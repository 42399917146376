import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import ContainerHero from '../components/ContainerHero'
import SEO from '../components/SEO'
import ContentChat from '../components/ContentChat'

const Livechat = () => {
  useEffect(() => {
    const tidioscript = document.createElement('script')
    tidioscript.async = true
    tidioscript.defer = true
    tidioscript.src = 'https://code.tidio.co/6v68gou9oeg8rfjc6bdfnwndebrj9dan.js'
    document.getElementById('chat').appendChild(tidioscript)
  }, [])
  return (
    <Layout>
      <SEO
        title="Live Chat"
        description="Kontaktieren Sie uns über unseren Live Chat."
      />
      <Container>
        <ContainerHero newsletter>
          <ContentChat>
            <PageTitle>Live Chat</PageTitle>
            <div id="chat"></div>
          </ContentChat>
        </ContainerHero>
      </Container>
    </Layout>
  )
}

export default Livechat
