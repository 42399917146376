import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    padding: 30px 15px;
    border-bottom: 2px solid #000;
    @media (min-width: ${props => props.theme.responsive.medium}) {
      padding: 30px;
    }
  }
`

const ChatContainer = styled.div`
  width: 100%;
  background: linear-gradient(115deg, #eff2f6 0%, #eff2f6 100%);
  @media (max-width: ${props => props.theme.responsive.smallMax}) {
    iframe {
      max-height: calc(100vh - 86px - 100px);
    }
  }
`

const ContentFernwartung = props => {
  return (
    <Container>
      {props.children}
      <ChatContainer>
        <iframe
          src="https://www.tidio.com/talk/6v68gou9oeg8rfjc6bdfnwndebrj9dan"
          height="800"
          width="100%"
        />
      </ChatContainer>
    </Container>
  )
}

export default ContentFernwartung
